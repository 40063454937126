import React from 'react';
import Link from 'next/link';
import { IHomeBanner } from '../models';
import localizedRoute from '../utils/localizedRoute';

const DiscountBanner = ({ banner }: { banner?: IHomeBanner }) => {
  return (
    <div className="relative">
      <img
        src={banner?.image || '/img/placeholders/hero-placeholder.jpg'}
        alt="Banner"
        style={{ height: '100vh', width: '100%' }}
        className="object-cover"
      />
      <div className="absolute bg-black bg-opacity-20 h-full w-full top-0 left-0"></div>

      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <div className="flex flex-col items-center text-white">
          <div className="mb-10">
            <h2 className="text-white uppercase text-4xl md:text-6xl mb-5 text-center">
              {banner?.title}
            </h2>
            {/* <p className="opacity-80 text-md md:text-lg text-center">
              {banner?.text}
            </p> */}
          </div>

          <div className="flex">
            <Link href={localizedRoute(banner?.btn_link1 || '')}>
              <a className="bg-primary text-white font-sans font-medium px-4 py-6 rounded-md uppercase cursor-pointer w-70 sm:w-56 inline-block text-center">
                {banner?.btn_text1}
              </a>
            </Link>
            {banner && banner.btn_link2.length > 0 ? (
              <>
                <Link href={localizedRoute(banner?.btn_link2 || '')}>
                  <a className="ml-2 bg-primary text-white font-sans font-medium px-4 py-6 rounded-md uppercase cursor-pointer w-70 sm:w-56 inline-block text-center">
                    {banner?.btn_text2}
                  </a>
                </Link>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiscountBanner;
